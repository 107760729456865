import React from "react"

import * as  menuTeamNavStyles from './menu-team-nav.module.scss'
import { Link } from "gatsby";
import LikeButton from "../../../core-module/atoms/like-button/like-button";
import TrooperButton from "../../../core-module/atoms/trooper-button/trooper-button";
import WebshopButton from "../../../core-module/atoms/webshop-button/webshop-button";

const MenuTeamNav = ({ items, id, trooper, isWebShopAvailable, webShopUrl, isWebShopActive }) => {
    return <>
        <div className={`${menuTeamNavStyles.menuwrapper}`}>
            <div className={menuTeamNavStyles.social}>
                <LikeButton className={menuTeamNavStyles.likeButton} interestId={id} />
                {isWebShopAvailable && 
                    <WebshopButton 
                        className={`${menuTeamNavStyles.webStoreButton} ${menuTeamNavStyles.webStoreDesktop}`} 
                        isWebShopActive={isWebShopActive} 
                        webShopUrl={webShopUrl}
                    />
                }
                <TrooperButton {...trooper}/>
            </div>
        </div>
        <div className={menuTeamNavStyles.social}>
                {isWebShopAvailable && 
                    <WebshopButton 
                        className={`${menuTeamNavStyles.webStoreButton} ${menuTeamNavStyles.webStoreMobile}`} 
                        isWebShopActive={isWebShopActive} 
                        webShopUrl={webShopUrl}
                    />
                }
            </div>
        <hr />
    </>
}

export default MenuTeamNav