// extracted by mini-css-extract-plugin
export var content = "reactions-module--content--wd+PT";
export var title = "reactions-module--title--q5LNx";
export var qtd = "reactions-module--qtd--nR5Wu";
export var reaction = "reactions-module--reaction--Zqzpl";
export var reaction__info = "reactions-module--reaction__info--Z09bR";
export var reaction__info__user = "reactions-module--reaction__info__user--kaeWC";
export var reaction__info__icon = "reactions-module--reaction__info__icon--u6u8W";
export var reaction__info__date = "reactions-module--reaction__info__date--EgGSL";
export var reaction__comment = "reactions-module--reaction__comment--8yPYe";
export var loadMore = "reactions-module--loadMore--Qqark";
export var loadMore__button = "reactions-module--loadMore__button--ZweYF";