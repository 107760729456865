// extracted by mini-css-extract-plugin
export var content = "slider-module--content--vWJIj";
export var controls = "slider-module--controls--8wQNH";
export var prev = "slider-module--prev--yz-zq";
export var next = "slider-module--next--hG0T0";
export var sliderButton = "slider-module--sliderButton--3venN";
export var sliderButton__hidden = "slider-module--sliderButton__hidden--Xez6U";
export var sliderButton__visible = "slider-module--sliderButton__visible--r3bSW";
export var sliderButton__img = "slider-module--sliderButton__img--cmyHT";
export var sliderButton__left = "slider-module--sliderButton__left--OVO2+";
export var sliderButton__svg = "slider-module--sliderButton__svg--zVLvx";