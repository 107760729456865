import React, { useEffect, useState } from 'react'
import axios from 'axios'

import * as styles from './article-footer.module.scss'
import SocialNetwork from '../../molecules/social-network/social-network'
import MoreAbout from '../../molecules/more-about/more-about'
import Reactions from '../../molecules/reactions/reactions'
import LikeButton from '../../../core-module/atoms/like-button/like-button'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { urlGgeneratedByLanguage } from "../../../../utils/utils"
import Advertisement, {classOptions} from "../../../advertisments-module/atoms/advertisement/advertisement"
import EgPower from '../../../advertisments-module/organisms/eg-power/eg-power'
import { loadAds } from "../../../../services/ads-service"
import ExtraAd from '../../../advertisments-module/organisms/extra-ad/extra-ad'
import Emitter from "../../../../services/emitter"

const ArticleFooter = ({ article, title }) => {

    const isBrowser = () => typeof window !== "undefined"
    const queryString = isBrowser() && window ? window.location.search : ''
    const urlParams = new URLSearchParams(queryString);
    const articleId = urlParams.get('id');

    const [comments, setComents] = useState(null)
    const { language } = useI18next()

    const [advertisement, setAdvertisement] = useState('')


    useEffect(() => {
        getComments();
    }, [articleId])

    //advertisments
     useEffect(() => {
        loadAds(language, `entity_id=${articleId}&entity_type=articles`).then(res => {    
            setAdvertisement(res.data.filter(add => add.position === "2")[0])
        })
    }, [language, articleId])

    
    useEffect(() => {
        Emitter.on('ADS_RANDOM_EVENT', function(result){
            setAdvertisement(result)
        })
    }, [Emitter])

    const getComments = () => {
        axios.get(`${urlGgeneratedByLanguage(language)}/api/comments?_format=json&id=${articleId}`).then((result) => {
            let commentDetails = result.data.map(result => { return { id: result.article_id, userId: result.user_id, user: result.user_name, date: result.postdate, comment: result.comment } });
            setComents(commentDetails)
        })
    }

    const moreAbout = article?.moreDetails
    const articleFooterTitle = useTranslation().t('article.footer.title')

    return (
        <div className={`container ${styles.content}`}>
            <div className={styles.social}>{ /*for left space to match top component */}</div>

            <div className={styles.wrapper}>
                <div className={styles.socialComments}>
                    <div className={styles.social}>
                        <div className={styles.social__socialNetwork}>
                            <div className={styles.sharing}>
                                 <SocialNetwork horizontal={true} hideLikeButton={true} className={styles.socialNetwork} title={title} />       
                            </div>
                        </div>
                    </div>
                    <div className={styles.firstLayerAdvertisement}>
                        {advertisement && <Advertisement mediaUrl={advertisement.media_link}
                                addUUID={`${advertisement.id}-landing-${advertisement.position}`}
                                destinationLink={advertisement.url}
                                mediaType={advertisement.media_type}
                                campaignId={advertisement.campaignId} 
                        classOption={classOptions.advertisementRandom}/>}
                    </div>

                    <div className={styles.colsonMobile}>
                        <ExtraAd />
                    </div>

                    {!!comments && !!articleId && <Reactions items={comments} articleId={articleId} refresh={getComments} />}
                   
                </div>
                {!!moreAbout.length && <MoreAbout items={moreAbout} />}
                <div className={styles.egPower}>
                    <EgPower />
                </div>
            </div>
            

            <div className={styles.advertisement}>
            {/*advertisement && <Advertisement mediaUrl={advertisement.media_link}
                            addUUID={`${advertisement.id}-landing-${advertisement.position}`}
                            destinationLink={advertisement.url}
                            mediaType={advertisement.media_type}
                            campaignId={advertisement.campaignId} 
    classOption={classOptions.advertisementRandom}/>*/}
            </div>
        </div>
    )
}


export default ArticleFooter
