import React from 'react'
import * as WebShopStyle from './webshop-button.module.scss'
import storeIcon from '../../../../images/store-solid.svg'
import comingSoon from '../../../../images/coming-soon.png'
import {redirect} from '../../../../utils/utils'

const WebShop = ({className='', webShopUrl, isWebShopActive}) => {

    const redirectUrl = () => {
        isWebShopActive && redirect(webShopUrl, '_blank')
    }

    return (
        <div className={`${WebShopStyle.button} ${className}`} onClick={redirectUrl}>
                    {!isWebShopActive && <img src={comingSoon} className={WebShopStyle.backgroundComing} />}
                    <div className={`${WebShopStyle.contentButton} ${isWebShopActive ? '' : WebShopStyle.withComingSoon}`}>
                        <img src={storeIcon} className={WebShopStyle.imageIcon} alt={'store icon'}/>
                        <p>WEB STORE</p>
                    </div>
        </div>
    )
}

export default WebShop;