import React, { useEffect, useState } from 'react'
import axios from 'axios'

import * as styles from './carousel-entity.module.scss'
import { useI18next } from 'gatsby-plugin-react-i18next';
import { createUrlSlug, urlGgeneratedByLanguage } from "../../../../utils/utils"
import CarouselSection from '../carousel-section/carousel-section';
import ArticlePreview from '../../molecules/article-preview/article-preview';
import { ArticlesPageCount } from '../../../../pages/main/article';

/**
 * 
 * @param { articleId, entity, entityType }
 * @returns a component rendering a carousel group for a given entity type according to entity.id 
 * if there is an articleId it will exclude that article from the list.
 */
const CarouselEntity = ({ articleId, entity, entityType }) => {
    const { language } = useI18next()
    const [carousel, setCarousel] = useState(null)
    const [currentPage, setCurrentPage] = useState(0)

    const getArticleId = (index) => {
        if(carousel !== null) {
            index += carousel.articles.length
        }
        return index
    }

    const loadArticles = () => {
        axios.get(`${urlGgeneratedByLanguage(language)}/api/view/articles?_format=json&${entityType}=${entity.id}&page=${currentPage}`).then(res => {
            let pageCount = res?.data?.page_count
            if(pageCount < ArticlesPageCount) {
                return;
            }
            let articles = res?.data?.data
            if (articleId) { articles = res.data?.filter(article => articleId && articleId !== article.id) }

            const carouselArticles = articles.map((article, index) => (
                <div key={`entity_article_${getArticleId(index, articles.length)}`} className={'slider__item'}>
                    <ArticlePreview article={article} horizontal={false} hideTags={true} />
                </div>
            ))            
            let allArticles = carousel !== null ? [...carousel.articles, ...carouselArticles] : carouselArticles
            setCarousel({ title: entity.title, articles: allArticles, linkTo: `/main/entity/${createUrlSlug(entity.title)}?id=${entity.id}` })
        })
        setCurrentPage((currentPage) => currentPage + 1)
    }

    useEffect(() => {
        loadArticles()
    }, [language])

    return (
        <div className={`${styles.content}`}>
            {carousel && carousel.articles?.length > 0 && <CarouselSection {...carousel} loadMore={loadArticles}/>}
        </div>
    )
}

export default CarouselEntity
