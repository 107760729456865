// extracted by mini-css-extract-plugin
export var content = "article-content-module--content--YeYPB";
export var colson = "article-content-module--colson--te21b";
export var advertisement = "article-content-module--advertisement--bBw68";
export var header = "article-content-module--header--whMta";
export var header__social = "article-content-module--header__social--tybxx";
export var header__title = "article-content-module--header__title--pDbpP";
export var header__title__subtitle = "article-content-module--header__title__subtitle--PPS7e";
export var header__title__subtitle__item = "article-content-module--header__title__subtitle__item--JTRb1";
export var header__title__subtitle__date__icon = "article-content-module--header__title__subtitle__date__icon--ElXVq";
export var header__title__subtitle__profile = "article-content-module--header__title__subtitle__profile--8ZOvV";
export var body = "article-content-module--body--5d8Je";
export var landing = "article-content-module--landing--sh+P0";
export var landing__mediaShare = "article-content-module--landing__mediaShare--lHIDc";
export var landing__socialNetwork = "article-content-module--landing__socialNetwork--b7EBL";
export var landing__content = "article-content-module--landing__content--c9HkP";
export var info = "article-content-module--info--sNvaL";
export var image = "article-content-module--image--EePbi";
export var image__featured = "article-content-module--image__featured--DV+iZ";
export var imageLabel = "article-content-module--imageLabel--kNt4d";
export var imageAlt = "article-content-module--imageAlt--zXplC";
export var htmlContent = "article-content-module--htmlContent--znT0z";
export var middleAdvertisement = "article-content-module--middleAdvertisement--5m7c8";
export var bottomAdvertisement = "article-content-module--bottomAdvertisement--eusfp";
export var landing__advertisement = "article-content-module--landing__advertisement--RordY";
export var header__title__subtitle__stopwatch = "article-content-module--header__title__subtitle__stopwatch--SodWX";