import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as style from './like-button.module.scss'
import React, { useEffect, useState } from "react"
import { getUserId } from "../../../../services/user-service"
import { likeStorage } from "../../../../services/storage-service"
import { isBrowser, urlGgeneratedByLanguage } from "../../../../utils/utils"
import axios from 'axios'
import { useI18next } from "gatsby-plugin-react-i18next"
import Emitter from "../../../../services/emitter"

const LikeButton = ({likes = 0}) => {
    const { language } = useI18next()

    const userId = getUserId()
    const queryString = isBrowser() && window ? window.location.search : ''
    const urlParams = new URLSearchParams(queryString);
    const articleId = urlParams.get('id');
    const [isArticleLiked, setIsArticleLiked] = useState(false)
    const [amountLikes, setAmountLikes] = useState(likes)

    useEffect(() => {
        axios.post(`${urlGgeneratedByLanguage(language)}/api/liked-or-not`, {id: (parseInt(userId) + 9999), article_id: articleId}).then(res => {
            setIsArticleLiked(res.data.liked === 1)
        }).catch(error => {
            console.error(error)
        })
    }, [])

    const like = () => {

        if(isArticleLiked) {
            return false;
        }

        if(!userId) {
            Emitter.emit('OPEN_SIDE_BAR', 3);
            return
        }

        axios.post(`${urlGgeneratedByLanguage(language)}/api/article-like`, {user_id: userId, article_id: articleId}).then(res => {
            likeStorage.save(articleId)
            let newLike = isNaN(parseInt(amountLikes)) ? 1 : parseInt(amountLikes) + 1
            setAmountLikes(newLike)
            setIsArticleLiked(true)
        }).catch(error => {
            console.log(error)
        })
    }

    return <div className={style.contentLikeButton} onClick={like}>
            <div className={`${isArticleLiked ? style.buttonActive : style.buttonInactive}`}>
                <FontAwesomeIcon icon="thumbs-up" size="lg"/>
            </div>
            {amountLikes && <p>{amountLikes}</p>}
            {!amountLikes && <p>&nbsp;</p>}
        </div>
}

export default LikeButton