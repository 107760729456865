import React from "react"
import PropTypes from "prop-types"

import * as  styles from './social-network.module.scss'

import facebook from '../../../../images/facebook-icon.svg'
import whatsapp from '../../../../images/whatsapp-icon.svg'
import twitter from '../../../../images/twitter-icon.svg'
import linkedin from '../../../../images/linkedin-icon.svg'
import share from '../../../../images/share.svg'
import { RWebShare } from "react-web-share"
import LikeButton from "../../../main-page-module/atoms/like-button/like-button"

const SocialNetwork = ({ title, horizontal, location, className, likes, hideLikeButton = false }) => {
    
    
    const isBrowser = () => typeof window !== "undefined"    
    const url = isBrowser() && window ? window.location.href : ''    
    
    return (
        <div className={`${styles.content} ${!!horizontal ? styles.horizontal : ''} ${className ? className : ''}`}>
            <a key='facebook_button' className={styles.item} target='_blank' rel="noreferrer" href={`https://www.facebook.com/sharer.php?u=${url}`} >
                <img src={facebook} alt='facebook button' />
            </a>
            <a key='twitter_button' className={styles.item} target='_blank' rel="noreferrer" href={`https://twitter.com/share?text=${url}`} >
                <img src={twitter} alt='twitter button' />
            </a>
            <a key='whatsapp_button' className={styles.item} target='_blank' rel="noreferrer" href={`https://api.whatsapp.com/send?text=${url}`} >
                <img src={whatsapp} alt='whatsapp button' />
            </a>
            <a key='linkedin_button' className={styles.item} target='_blank' rel="noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`} >
                <img src={linkedin} alt='linkedin button' />
            </a>
            {!hideLikeButton && <LikeButton likes={likes}/>}
            <RWebShare
                data={{
                    url:  url,
                    title: title,
                }}
                onClick={() => console.log("shared successfully!")}>
                    <div className={styles.item}>
                         <img className={styles.generic_share} src={share} alt='whatsapp button' />
                    </div>
            </RWebShare>
        </div>
    )
}

SocialNetwork.propTypes = {
    horizontal: PropTypes.bool,
}

export default SocialNetwork
