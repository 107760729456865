import React, { useEffect, useRef, useState } from 'react'
import * as style from './gallery.module.scss'

export const isValidImageStructure = imagesList => (imagesList && imagesList.length > 0 && imagesList.filter(image => image.gallary_image_url === '').length === 0)

const Gallery = ({images}) => {
    const [current, setCurrent] = useState(0)
    const [isRunning, setIsRunning] = useState(true);
    const [reset, setReset] = useState(0);
    const [delay, setDelay] = useState(3500);

    function useInterval(callback, delay, resetValue) {
        const savedCallback = useRef();
        const savedReset = useRef();
        // Remember the latest function.
        useEffect(() => {
            savedCallback.current = callback;
            savedReset.current = resetValue;
        }, [callback, resetValue]);
        
        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null || resetValue !== savedReset.current) {
                let id = setInterval(tick, delay);
                return () => {
                    clearInterval(id);
                };
            }
        }, [delay, resetValue]);
    }

    useInterval(() => {
            // Your custom logic here
            next()
        },
        isRunning ? delay : null,
        reset
    )

    const resetSlider = () => setReset(c => c + 1);

    const selectImage = (position) => {
        resetSlider()
        setCurrent(position)
    }

    const pressNext = () => {
        next()
        resetSlider()
    }
    const pressPrev = () => {
        prev()
        resetSlider()
    }

    const next = () => {
        let newCurrent = current + 1
        setCurrent(newCurrent >= images.length ? 0 : newCurrent)
    }

    const prev = () => {
        let newCurrent = current - 1
        setCurrent(newCurrent <= 0 ? 0 : newCurrent)
    }

    return <>
            <div className={style.main}>
                <div className={style.buttons}>
                    <div className={style.button} onClick={pressPrev}>{"<"}</div>
                    <div className={style.button} onClick={pressNext}>{">"}</div>
                </div>
                <img src={images[current].gallary_image_url} className={style.mainImage} alt={images[current].gallary_image_alt}/>
            </div>
            <p className={style.legend}>{images[current].caption}</p>
            <div className={style.thumbs}>
                {images.map((item, pos) => <img src={item.gallary_image_url} className={(current === pos ? style.select : '')} key={pos} onClick={() => selectImage(pos)}/>)}
            </div>
        </>
}

export default Gallery