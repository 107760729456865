// extracted by mini-css-extract-plugin
export var content = "article-footer-module--content--VMLYq";
export var social = "article-footer-module--social--5tq5R";
export var advertisement = "article-footer-module--advertisement--0MKFW";
export var egPower = "article-footer-module--egPower--TEuVZ";
export var wrapper = "article-footer-module--wrapper--VsWnD";
export var firstLayerAdvertisement = "article-footer-module--firstLayerAdvertisement--NmM2l";
export var socialComments = "article-footer-module--socialComments--CvTg0";
export var social__title = "article-footer-module--social__title--vsY6Z";
export var social__socialNetwork = "article-footer-module--social__socialNetwork--tBbAl";
export var likeButton = "article-footer-module--likeButton--zDpyf";
export var colsonMobile = "article-footer-module--colsonMobile--0x6Ga";