import { Link } from "gatsby"
import React from 'react'

import * as teamHeaderStyles from './team-header-section.module.scss'
// import leftShadow from '../../../../images/left-cover-shadow.svg'
// import rightShadow from '../../../../images/right-cover-shadow.svg'
import ProfileRoundImage from '../../../core-module/atoms/profile-round-image/profile-round-image'
import MenuTeamNav from "../../atoms/menu-team-nav/menu-team-nav"
import { createUrlSlug } from "../../../../utils/utils"

const TeamHeaderSection = ({ 
                                teamName, 
                                teamUrl, 
                                cover, 
                                coverAltText,
                                crest,
                                logo_alt_text,
                                id,
                                trooper,
                                entityId,
                                title,
                                type,
                                isWebShopAvailable,
                                isWebShopActive,
                                webShopUrl 
                            }) => {
    let items = [];
    if(!!entityId) {
        items = [
            /* after demo these are to be hidden */
            { to: `/main/entity/${createUrlSlug(title)}?id=${entityId}`, description: 'Nieuws', active: true },
            // { to: '#', description: 'Seizoen' },
            //  { to: '#', description: 'Teams' },
            //  { to: '#', description: 'Transfers' },
            //  { to: '#', description: 'Info' }*/
        ]    
    }


    return (
        <div className={`${!teamName && !cover ? teamHeaderStyles.notVisibleMobile : ''}`}>
            { teamName
              && 
              <>
                <div className={`${teamHeaderStyles.teamheader}`}>
                    <div className={`${teamHeaderStyles.cover}`}>
                    {/* Test if the cover is not empty - If it's empty then it will hide the IMG*/
                        cover  
                        && <img key='cover_image' title={title} className={teamHeaderStyles.cover__image} alt={coverAltText} src={cover} style={{ objectFit: "cover" }} />}
                    </div>
                    <div className={`${teamHeaderStyles.info}`}>
                        <div className={`container ${teamHeaderStyles.info__tagname}`}>
                            <div className={teamHeaderStyles.info__tagname__logo}>
                                <Link to={teamUrl}>
                                    <ProfileRoundImage src={crest} alt_text={logo_alt_text} entityType={type}/>
                                </Link>
                            </div>
                            <div className={teamHeaderStyles.info__tagname__title}>
                                <Link to={teamUrl}><h1>{teamName}</h1></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <MenuTeamNav items={items} id={id} trooper={trooper} isWebShopActive={isWebShopActive} isWebShopAvailable={isWebShopAvailable} webShopUrl={webShopUrl}/>
            </>
        }
        </div>
    )
}

export default TeamHeaderSection
